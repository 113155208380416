//
// Main Javascript file
// Author: Sebastian Adel (http://www.freshmonday.ro)
//

/**
 *  Global Vars
 */
// Global
// var $viewport                    = $('html, body');
// var $window                      = $(window);
// var windowHeight                 = $window.height();
// var windowHeightPadded           = windowHeight / 1.2;
// var $main                        = $('.main');
// var mainStateClass               = 'has-floating';

// Textarea
// var $textarea                    = $('.js-textarea');

// Header
var headerStateNavClass          = 'has-open-menu';
var $header                      = $('.js-header');

// Nav
var $nav                         = $('.js-nav');
var $navAction                   = $('.js-nav-action');
var navStateClass                = 'is-visible';

// Map
// var $mapMarkers                  = $('.js-map-markers');
var $map                         = $('.js-google-map');
// var mapInfo                      = null;

// Contact
var $contact                     = $('.js-contact-form');
var $contactContent              = $('.js-contact-content');
var $contactNotice               = $('.js-contact-notice');

/**
 * [autodorina Object]
 * @type {Object}
 */
var autodorina = {

  init: function() {

    // Links actions
    autodorina.linksHandler();

    // Detect browsers
    autodorina.detectBrowser();

    // Navigation handler
    autodorina.navHandler();

    // Gallery Carousel
    autodorina.galleryCarousel();

    // Map Handler
    autodorina.googleMapHandler();

    // Contact handler
    autodorina.contactHandler();

    // Fancybox
    autodorina.openFancyBox();

    // Smooth Scroll
    autodorina.smoothScrollHandler();

    // Slider Handlers
    autodorina.homepageSliderHandler();

  },

  // Links handler
  linksHandler: function() {
    // Initialize function
    function __init () {
      // Open in new window links with rel=external code
      $('a[rel="external"]').attr('target','_blank');
      // Prevent default action on # (hash) links
      $('a[href="#"]').on('click', function(e) { e.preventDefault(); });
    }

    // Initialize module
    return __init();
  },

  // Detect browsers
  detectBrowser: function() {
    // Initialize function
    function __init() {
      isIE = detectIE();
      // Add class to HTML element
      if (isIE) { $('html').addClass('ie '+isIE); }
    }

    // Detect IE
    function detectIE() {
      var ua      = window.navigator.userAgent;
      var msie    = ua.indexOf('MSIE ');
      var trident = ua.indexOf('Trident/');

      if (msie > 0) {
        // IE 10 or older => return version number
        return 'ie'+parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
      }

      if (trident > 0) {
        // IE 11 (or newer) => return version number
        var rv = ua.indexOf('rv:');
        return 'ie'+parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
      }
      // Other browser
      return false;
    }

    // Initialize module
    return __init();
  },

  // Navigation handler
  navHandler: function(action) {
      // Set variable to false if not defined
      action = action || null;

      // Initialize function
      function __init() {
          switch (action) {
              case 'navActions':
                  // Nav actions
                  navActions();
              break;
              case 'navClose':
                  // Nav close
                  navClose();
              break;
              case 'navOpen':
                  // Nav close
                  navOpen();
              break;
              default:
              // Nav actions
              navActions();
          }
      }
      // Nav actions
      function navActions() {
          $navAction.unbind('click').on('click', function(e) {
              e.preventDefault();
              if ($nav.length > 0) {
                  if ($header.hasClass(headerStateNavClass)) {
                      navClose();
                  } else {
                      navOpen();
                  }
              }
          });
      }
      // Nav open
      function navOpen() {
              $nav.addClass(navStateClass);
              $header.addClass(headerStateNavClass);
      }
      // Nav close
      function navClose() {
              $header.removeClass(headerStateNavClass);
              $nav.removeClass(navStateClass);
      }

      // Initialize module
      return __init();
  },

  // Contact handler
  contactHandler: function() {
      // Initialize function
      function __init() {
          if ($contact.length > 0) {
              // Vars
              var contactHeight = $contact.outerHeight();

              // Validate contact form
              $contact.h5Validate();

              // Process contact form
              $contact.submit(function(e) {
                  // Vars
                  var result = $contact.h5Validate('allValid');
                  var data;
                  var url;

                  if ( result === true ) {
                      // Serialize contact data
                      data = $(this).serialize();
                      // Get URL from action
                      url = $(this).attr('action');
                      method = $(this).attr('method');

                      // Send request
                      $.ajax({
                          url         : url,
                          data        : data,
                          type        : method,
                          cache       : false,
                          error       : function(result) {
                              // Log message
                              console.log(result);
                          },
                          success     : function(result) {
                              result = result || null;
                              // Log message
                              console.log(result);
                              // Prevent resizing
                              $contact.css({'min-height': contactHeight + 'px'});
                              // Hide form content for 10s
                              $contactContent.velocity("fadeOut", { duration: 800 })
                                             .velocity("fadeIn", { delay: 10000, duration: 1000 });
                              // Show form notice for 9s
                              $contactNotice.velocity("fadeIn", { delay: 850, duration: 1500 })
                                             .velocity("fadeOut", { delay: 7000, duration: 800 });
                              // Clear fields
                              $contact.trigger('reset');
                          }
                      });
                  }
                  // Prevent actual form submit
                  e.preventDefault();
              });
          }
      }

      // Initialize module
      return __init();
  },

  // Gallery Carousel
  galleryCarousel: function() {
    $('.js-gallery-carousel').slick({
      dots: true,
      arrows: false,
      infinite: true,
      autoplay: true,
      speed: 3000,
      slidesToShow: 5,
      slidesToScroll: 5,
      autoplaySpeed: 2500,
      cssEase: 'linear',

      responsive: [
        {
          breakpoint: 1100,
          settings: {
            speed: 1200,
            autoplaySpeed: 1500,
            slidesToShow: 3,
            slidesToScroll: 3
          }
        },
        {
        breakpoint: 700,
          settings: {
            speed: 1200,
            autoplaySpeed: 1500,
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
        breakpoint: 480,
          settings: {
            speed: 1200,
            autoplaySpeed: 1500,
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    });
  },

  // Open fancybox modal
  openFancyBox: function() {
    $("a.js-fancybox").fancybox({
      margin : 10,
      padding: 0,
      helpers: {
        overlay: { // to prevent it from jumping on top of the page
          locked: false
        }
      },
      afterShow: function() {
        $('.fancybox-wrap').swipe({
          swipe : function(event, direction) {
            if (direction === 'left' || direction === 'up') {
              $.fancybox.prev( direction );
            } else {
              $.fancybox.next( direction );
            }
          }
        });
      },
      afterLoad : function() {

      }
    });
  },

  googleMapHandler: function() {

    // Get Map Coordinates
    $latitude = $map.data('latitude');
    $longitude = $map.data('longitude');
    $coordinates = $latitude+','+$longitude;

    // Initialize Module
    function __init() {
        // If we have $map in the DOM
        if ($map.length > 0) {
            // Render default map
            $map.each(function() {
                renderMap( $(this) );
            });
        }
    }

    function renderMap($latitude, $longitude) {
      var mapOptions = {
              allowZoom           :   true,
              scrollwheel         :   false,
              draggable           :   true,
              centerOnPin         :   false,
              zoom                :   7,
              disableDefaultUI    :   true,
              scaleControl        :   false,
              center              :   new google.maps.LatLng($latitude,$longitude),
              mapTypeId           :   google.maps.MapTypeId.ROADMAP,
              zoomControl         :   true,
              zoomControlOptions  :   {
                  style: google.maps.ZoomControlStyle.SMALL,
                  position: google.maps.ControlPosition.RIGHT_TOP
              }
      };

      // Create map
      map = new google.maps.Map($element[0], mapOptions);

      // Add marker
      addMarker($latitude, $longitude, map);

      // Reset map on resize
      google.maps.event.addDomListener(window, 'resize', function() {
          // Trigger map repaint
          google.maps.event.trigger(map, 'resize');
          // Trigger map center
          centerMap(map);
      });

      // Disable right click zoom
      google.maps.event.addListener(map, 'rightclick', function() {
          map.set('disableDoubleClickZoom', true);
      });
      // Enable normal zoom
      google.maps.event.addListener(map, 'click', function() {
          map.set('disableDoubleClickZoom', false);
      });
    }

    // Add map markers function
    function addMarker(latitude, longitude, map) {

      new google.maps.Marker({
          map: map,
          draggable: false,
          position: new google.maps.LatLng($latitude, $longitude)
      });
    }

    // Initialize Module
    return __init();
  },

  // Smooth Scroll Handler
  smoothScrollHandler: function() {
    $(".js-smooth-scroll").click(function(e) {
      e.preventDefault();

      var target = $(this).attr("href");
      var speed = 2500;

      if (!target) {
        target = $(this).find("a").attr("href");
      }

      anchorScroll($(target), speed );
    });

    function anchorScroll(target_obj, speed) {
      var target_offset = target_obj.offset();

      $("html,body").animate({
        scrollTop: target_offset.top
      }, {
        duration: speed,
        easing: "easeInOutQuint"
      });
    }
  },

  // HomepageSlider
  homepageSliderHandler: function() {
    $('.js-homepage-slider').slick({
      dots: true,
      arrows: false,
      infinite: true,
      speed: 500,
      autoplay: true,
      adaptiveHeight: true,
      cssEase: 'linear'
    });
  }
};



/**
 * Document ready (loaded)
 */
jQuery(document).ready(function() {
  // Init scripts
  autodorina.init();
});

/**
 *  Document load (in process of loading)
 */
jQuery(window).load(function() {
  // Do stuff
});
